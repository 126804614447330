<template>
  <div>
    <v-row class="mt-4 mx-4">
      <v-col cols="6">
        <v-card>
          <v-card-title><v-icon class="mr-2">mdi-account</v-icon>My Account</v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="4">
                <v-text-field :value="value.email" v-model.trim="value.email" label="Email" disabled></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field :value="value.phone" v-model.trim="value.phone" label="Phone" disabled></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="4">
                <v-text-field
                  :value="value.displayName"
                  v-model.trim="value.displayName"
                  label="Display Name"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field :value="value.firstName" v-model.trim="value.firstName" label="First Name"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field :value="value.lastName" v-model.trim="value.lastName" label="Last Name"></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="auto">
                <v-btn color="green" dark @click="onSubmit">Save</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col col="6">
        <v-card>
          <v-card-title>Reset Password</v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  :value="reset.currentPassword"
                  v-model.trim="reset.currentPassword"
                  label="Current / Temporary Password"
                  :append-icon="showCurrent ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showCurrent ? 'text' : 'password'"
                  @click:append="showCurrent = !showCurrent"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :value="reset.newPassword"
                  v-model.trim="reset.newPassword"
                  label="New Password"
                  :append-icon="showNew ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showNew ? 'text' : 'password'"
                  @click:append="showNew = !showNew"
                  @input="checkPasswordStrength"
                ></v-text-field>
                <v-card v-if="reset.newPassword.length">
                  <v-card-text>
                    <div>
                      Length:
                      <span :class="strength.length >= 8 ? 'green--text' : 'red--text'">{{ strength.length }}</span>
                    </div>
                    <div>
                      <v-icon :class="strength.hasUpper ? 'green--text' : 'red--text'">
                        {{ strength.hasUpper ? 'mdi-close-thick' : 'mdi-check-bold' }}
                      </v-icon>
                      Uppercase letter
                    </div>
                    <div>
                      <v-icon :class="strength.hasLower ? 'green--text' : 'red--text'">
                        {{ strength.hasLower ? 'mdi-close-thick' : 'mdi-check-bold' }}
                      </v-icon>
                      Lowercase letter
                    </div>
                    <div>
                      <v-icon :class="strength.hasNum ? 'green--text' : 'red--text'">
                        {{ strength.hasNum ? 'mdi-close-thick' : 'mdi-check-bold' }}
                      </v-icon>
                      Number
                    </div>
                    <div>
                      <v-icon :class="strength.hasSpecial ? 'green--text' : 'red--text'">
                        {{ strength.hasSpecial ? 'mdi-close-thick' : 'mdi-check-bold' }}
                      </v-icon>
                      Symbols (e.g. ~!@#$%^&)
                    </div>
                    <div>
                      Strength:
                      <span class="font-weight-bold red--text" v-show="strength.score == 0">Very Low</span>
                      <span class="font-weight-bold red--text" v-show="strength.score == 1">Low</span>
                      <span class="font-weight-bold orange--text" v-show="strength.score == 2">Medium</span>
                      <span class="font-weight-bold green--text" v-show="strength.score == 3">High</span>
                      <span class="font-weight-bold green--text" v-show="strength.score == 4">Very High</span>
                    </div>
                    <v-progress-linear :value="(strength.guesses / 12) * 100"></v-progress-linear>
                    <!-- <div v-for="(s, i) of strength.feedback.suggestions" :key="i">
                      {{ s }}
                    </div> -->
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :value="reset.newPasswordConfirm"
                  v-model.trim="reset.newPasswordConfirm"
                  label="Confirm New Password"
                  :append-icon="showConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showConfirm ? 'text' : 'password'"
                  @click:append="showConfirm = !showConfirm"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense v-if="disableUpdateErrorText">
              <v-col cols="12">
                <v-alert type="warning">{{disableUpdateErrorText}}</v-alert>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="auto">
                <v-btn color="success" @click="resetPassword" :disabled="disableUpdate"> Update Password </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card></v-col
      >
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { UPDATE_MYSELF, UPDATE_PASSWORD, CHECK_PASSWORD } from '@/store/modules/User/actions';

export default {
  name: 'MyAccount',
  inject: ['eventHub'],
  components: {},
  data() {
    return {
      value: {},
      reset: {
        currentPassword: '',
        newPassword: '',
        newPasswordConfirm: '',
      },
      showCurrent: false,
      showNew: false,
      showConfirm: false,
      strength: {},
    };
  },
  computed: {
    ...mapGetters('user', ['me']),
    disableUpdate() {
      if (!this.reset.currentPassword || !this.reset.newPassword || !this.reset.newPasswordConfirm) return true;
      else if (this.reset.newPassword !== this.reset.newPasswordConfirm) return true;
      return !this.strength.validated;
    },
    disableUpdateErrorText() {
      if (!this.reset.currentPassword || !this.reset.newPassword || !this.reset.newPasswordConfirm) return null; 

      if (this.reset.newPassword !== this.reset.newPasswordConfirm) {
        return 'New Password does not match.';
      } else if (!this.strength.validated) {
        return 'Please increase password strength.';
      }
      return null;
    }
  },
  mounted() {
    this.value = {
      email: this.me.email,
      phone: this.me.phone,
      displayName: this.me.displayName,
      firstName: this.me.firstName,
      lastName: this.me.lastName,
    };
  },
  methods: {
    ...mapActions('user', [UPDATE_MYSELF, UPDATE_PASSWORD, CHECK_PASSWORD]),
    async onSubmit() {
      const response = await this.updateMyself({ ...this.me, ...this.value });
      if (response.done) this.$myalert.success('Account updated');
      else this.$myalert.error('Unable to update account');
    },
    async resetPassword() {
      if (this.reset.newPassword !== this.reset.newPasswordConfirm) this.$myalert.error('Password does not match');
      else {
        try {
          const r = await this.updatePassword({
            oldPassword: this.reset.currentPassword,
            newPassword: this.reset.newPassword,
          });
          if (r && r.done) this.$myalert.success('Password updated');
          else this.$myalert.error('Unable to update password');
        } catch (e) {
          this.$myalert.error('Unable to update password: ' + e.message);
        }
      }
    },
    async checkPasswordStrength() {
      this.strength = await this.checkPassword({ newPassword: this.reset.newPassword });
      this.strength.validated =
        this.strength.length >= 8 &&
        this.strength.hasUpper &&
        this.strength.hasLower &&
        this.strength.hasNum &&
        this.strength.hasSpecial &&
        this.strength.score >= 2;
    },
  },
  watch: {},
};
</script>

<style lang="scss"></style>
